
import { defineComponent } from 'vue';
import { employeeAppSelections } from '@/config/app.config';
import useCheckIn from '@/composables/useCheckIn';
import { useMainStore } from '@/stores/main.store';

export default defineComponent({
    name: 'CheckIn',
    props: {
        headerTitle: String,
    },
    emits: ['on-reset', 'on-hide-selection'],
    setup() {
        const store = useMainStore();

        const { 
            resetForm,
            onAppSelectNext,
            isValidEmployee,
        } = useCheckIn();

        const hideOption = (appId: string) => {
            if (appId === 'desk-booking') {
                return !store.customer?.hasDeskBooking;
            }
            return false;
        };

        return {
            employeeAppSelections,
            onAppSelectNext,
            resetForm,
            isValidEmployee,
            hideOption
        };
    },
});
